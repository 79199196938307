import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// Estilos
const NoticiasContainer = styled.div`
  animation: ${fadeIn} 2s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 15px;
  padding-top: 20px;
  @media (max-width: 768px) {
    padding-top: 0px;
  }
`;

const NoticiaStyled = styled.div`
  font-family: 'Lumier', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  background-color: #ffffff;
  margin: 20px;
  padding: 10px;
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const NoticiaTitulo = styled.h2`
  font-weight: bold;
  color: #a0f000;
  margin-top: 10px;
  text-align: center;
  font-size: 33px;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const NoticiaFecha = styled.h3`
  color: #979797;
  margin-top: -5px;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
`;

const NoticiaContenido = styled.p`
  font-family: 'RNSMiles';
  color: #3f3f3f;
  text-align: center;
  font-size: 17px;
  line-height: 1.2; 
  font-weight: bold; 
`;

const VerMasBoton = styled.a`
  background-color: #a0f000; 
  border: none;
  color: #000000;
  padding: 6px 14px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  margin: 10px;
  cursor: pointer;
`;

// Componentes
const Noticia = ({ noticia }) => {
  const partesFecha = noticia.fecha.split('/');
  const fechaObjeto = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]);
  const fechaFormateada = fechaObjeto.toLocaleDateString('es-ES', {
    weekday: 'long', day: '2-digit', month: 'long', year: 'numeric'
  });

  return (
    <NoticiaStyled>
      <NoticiaTitulo>{noticia.titulo}</NoticiaTitulo>
      <NoticiaFecha>Publicado: {fechaFormateada}</NoticiaFecha>
      {noticia.contenido.split('.').map((item, index) => (
        <NoticiaContenido key={index}>{item.trim()}</NoticiaContenido>
      ))}
      {noticia.enlaces.map((enlace, index) => (
        <VerMasBoton key={index} href={enlace.url} target="_blank">{enlace.texto}</VerMasBoton>
      ))}
    </NoticiaStyled>
  );
};

const NoticiaPrincipal = () => {
  const [noticias] = useState([
    {
      id: 1,
      titulo: 'Explora los Próximos Eventos!',
      fecha: '16/04/2024',
      contenido: '¡No te pierdas nuestra emocionante agenda de eventos en Net Sport Events! Tenemos actividades para todos los entusiastas del deporte. Consulta todos los eventos y asegúrate de reservar tu lugar.',
      enlaces: [{ texto: "Ver más", url: 'https://netsportacademy.com/eventos' }]
    },
    {
      id: 2,
      titulo: 'Última noticia!',
      fecha: '17/04/2024',
      contenido: 'Hoy es miércoles festivo en Sevilla, por lo tanto, no habrá escuela en el Club Río Grande. Consulta nuestro calendario para más detalles.',
      enlaces: [{ texto: "Ver más", url: 'https://netsportacademy.com/clubs/riogrande/calendarioriogrande' }]
    },









    {
      id: 3,
      titulo: '¡Abierto el plazo de inscripción del campus de verano de Club Airbus! ',
      fecha: '24/04/2024',
      contenido: '',
      enlaces: [
        { texto: "⁠Campus de Verano en Club Airbus", url: 'https://netsportacademy.com/clubs/airbus/campusdeverano' },
     
    
    ]
    },
    {
      id: 4,
      titulo: '¡Abierto el plazo de inscripción del campus de verano e intensivo de tenis de Bellavista Huelva Club!',
      fecha: '25/04/2024',
      contenido: '',
      enlaces: [
      
        { texto: "⁠Campus de Verano en Bellavista Huelva Club ", url: 'https://netsportacademy.com/clubs/bellavista/campusdeverano' },
        { texto: "Intensivos en Bellavista Huelva Club", url: 'https://netsportacademy.com/clubs/bellavista/intensivosdeverano' }
      ]
    },




 
    {
      id: 6,
      titulo: '¡Abierto el plazo de inscripción para el intensivo de tenis en el Club Río Grande!',
      fecha: '27/04/2024',
      contenido: '',
      enlaces: [
        { texto: "Intensivos en Club Río Grande", url: 'https://netsportacademy.com/clubs/riogrande/intensivosdeverano' },
     
      ]
    },



    
    {
      id: 7,
      titulo: '¡Inscripción e información general temporada 24 - 25 Bellavista Huelva Club!', 
      fecha: '10/08/2024',
      contenido: '',
      enlaces: [
        { texto: "Información general", url: 'https://netsportacademy.com/clubs/bellavista/informacioneinscripcionbhc' },
     
      ]
    },


    {
      id: 8,
      titulo: '¡Inscripción e información general temporada 24 - 25 Club Rio Grande!', 
      fecha: '12/08/2024',
      contenido: '',
      enlaces: [
        { texto: "Información general", url: 'https://netsportacademy.com/clubs/riogrande/informacioneinscripcionrg' },
     
      ]
    },


    {
      id: 9,
      titulo: '¡Inscripción Spartan Tenis Tour 2024!', 
      fecha: '8/08/2024',
      contenido: '',
      enlaces: [
        { texto: "Información general", url: 'https://netsportacademy.com/eventos/spartan2024' },
     
      ]
    },

    {
      id: 10,
      titulo: '¡Inscripción Spartan Adultos Tenis Tour 2024!', 
      fecha: '8/08/2024',
      contenido: '',
      enlaces: [
        { texto: "Información general", url: 'https://netsportacademy.com/eventos/spartanabsoluto2024' },
     
      ]
    },


    {
      id: 11,
      titulo: '¡Inscripción e información general Torneo Social Club Rio Grande 2024!', 
      fecha: '15/08/2024',
      contenido: '',
      enlaces: [
        { texto: "Información general", url: 'https://netsportacademy.com/eventos/TorneoSocialRioGrande' },
     
      ]
    },


    {
      id: 12,
      titulo: '¡Inscripción e información general Torneo NSTA Series 1.0 Bellavista Huelva Club!', 
      fecha: '17/9/2024',
      contenido: '',
      enlaces: [
        { texto: "Información general", url: 'https://netsportacademy.com/eventos/TorneoNstaSeries1.0' },
     
      ]
    },


   




















  ]);

  noticias.sort((a, b) => {
    const fechaA = new Date(a.fecha.split('/')[2], a.fecha.split('/')[1] - 1, a.fecha.split('/')[0]);
    const fechaB = new Date(b.fecha.split('/')[2], b.fecha.split('/')[1] - 1, b.fecha.split('/')[0]);
    return fechaB - fechaA;
  });

  return (
    <NoticiasContainer>
      {noticias.map(noticia => (
        <Noticia key={noticia.id} noticia={noticia} />
      ))}
    </NoticiasContainer>
  );
};

export default NoticiaPrincipal;
