import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';



const ContenedorEventos = styled.div`
position: relative; /* Modificado para permitir el posicionamiento absoluto del título */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: linear-gradient(135deg, #ece9e6 0%, #ffffff 100%);
  padding: 20px;
 padding-top: 120px;

 @media (max-width: 768px) {
  padding-top: 90px;
  }
`;

const EventoFichaStyled = styled.div`
  font-family: 'Lumier', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: calc(37% - 70px);
  margin: 20px; 
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  background-color: #ffffff;
  max-height: 740px;
  min-width: 350px;

  @media (max-width: 768px) {
    flex-basis: 100%;
    max-width: 550px; 
 
  }
`;

const ImagenContenedor = styled.div`
  width: 100%;
  overflow: hidden;

`;

const ImagenEvento = styled.img`
  width: 100%;
  max-height: 500px;
  min-height: 400px;
  object-fit: cover;
  object-position: top;
  transition: transform 0.5s ease;


  &:hover {
    transform: scale(1.07);
 
  }
`;

const NombreEvento = styled.h3`
  font-weight: bold;

  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 15px;
  text-align: left;
  width: 100%;
  color: #4b4b4b;
`;

const FechaEvento = styled.p`
  padding-left: 15px;
  text-align: left;
  width: 100%;
  margin-top: -10px;
  color: #4b4b4b;
`;

const DescripcionFicha = styled.p`
  font-family: 'RNSMiles';
  padding: 0 15px;
  text-align: left;
  width: 100%;
  margin-top: -5px;
  color: #4b4b4b;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 15px;
  
`;

const BotonLeerMas = styled.button`
  text-align: center;
  background-color: #a0f000;
  color: #000000;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 20px;
  margin-top: 15px;
  font-size: 12px;

  &:hover {
    background-color: #000000;
    color: white;
  }

  &:focus {
    outline: none;
  }
`;

const Titulo = styled.h1`
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'GarnetLight';
  font-weight: bold;
  font-size: 32px;
  z-index: 10;
  white-space: nowrap;
  padding: 10px;
/* border: 2px solid rgb(137, 137, 137); */
border-radius: 5px;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
letter-spacing: 1.5px;
line-height: 1.2;

color: #474747;

  

  @media (max-width: 768px) {
    font-size: 24px; /* Reduce el tamaño de la fuente en dispositivos móviles */
    top: 30px;
  }
`;

const BotonInscribete = styled(BotonLeerMas)` // Utiliza los mismos estilos que BotonLeerMas
  background-color: #007bff; // Cambia el color si lo deseas
  &:hover {
    background-color: #0056b3; // Cambia el color al pasar el mouse si lo deseas
  }
`;

const BotonesContenedor = styled.div`
  display: flex;
  justify-content: center; // Centra los botones horizontalmente
  gap: 10px; // Añade un pequeño espacio entre los botones
  margin-top: 15px; // Asegúrate de ajustar el margen superior según necesites
`;

const MensajeNoEventos = styled.div`
  font-family: 'Lumier', sans-serif;
  color: #4b4b4b;
  text-align: center;
  padding: 20px;
  margin: 20px 0;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
`;




// EVENTOS!!!!
const eventos = [


  {
    id: 1,
    nombre: 'NSTA Kids',
    fecha: '03/03/2024',
    descripcion: 'Este evento de carácter recreativo, esta dedicado a las edades comprendidas entre los 4 y los 12 años. El objetivo es adentrar a los más pequeños en la competición, que den sus primeros pasos en la misma, que aprendan las reglas de juego básicas, hagan nuevas amistades y, fundamentalmente, que se enamoren del tenis. Se disputan las siguientes categorías en formato mixto (masculinos y femeninos en conjunto):       Sub-6; sub-8; sub-10 y sub-12',
    imagenUrl: '/Events/TorneoNSTAKidsRosa.jpg',



    ruta: '/eventos/torneonstakids', // VISTA DEL EVENTO

    urlInscripcion: '', // FORMULARIO 
  
  },


  {
    id: 2,
    nombre: 'NSTA Family',
    fecha: '28/02/2024',
    descripcion: 'Es uno de los últimos eventos diseñados por NSTA con el objetivo de disfrutar del tenis en familia. Consiste en una jornada de clases de tenis dirigidas a todo el núcleo familiar, donde comparten la misma clase mama, papa, hij@s, abuelos, tíos, primos, etc. Siempre el grupo familiar debe ser mayor de 3 y menos de 6 participantes.',
    imagenUrl: '/Events/NSTAFAMILY.jpg',

    ruta: '/eventos/nstafamily', 

    urlInscripcion: '', // FORMULARIO 
  },


  {
    id: 3,
    nombre: '7º circuito tenis de Huelva',
    fecha: '01/03/2024 - 03/03/2024',
    descripcion: 'El torneo federado se celebrará del viernes 1 al domingo 3 de marzo, como parte del 7º circuito de tenis de Huelva.',
    imagenUrl: '/Events/7º circuito tenis de Huelva.jpg',

    ruta: '/eventos/7circuito', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 
  },

  {
    id: 4,
    nombre: 'Campeonato de Andalucía Alevín y Cadete Fase Provincial Huelva',
    fecha: '07/03/2024 - 10/03/2024',
    descripcion: 'La fase provincial del Campeonato de Andalucía, en las categorías Alevín y Cadete, se celebrará en el Bellavista Huelva Club. Este evento deportivo reunirá a los jóvenes talentos del tenis, quienes competirán por avanzar a la siguiente etapa del campeonato regional. Las instalaciones del Bellavista Huelva Club ofrecen un entorno ideal para la competición, prometiendo ser un escenario emocionante tanto para los participantes como para los espectadores.',
    imagenUrl: '/Events/CAMPEONATO DE ANDALUCIA ALEVIN Y CADETE.jpg',


    ruta: '/eventos/campeonatoalevincadete', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 


  },



  {
    id: 5,
    nombre: 'Intensivo Competición Semana Santa',
    fecha: '25/03/2024 - 27/03/2024',
    descripcion: 'Intensivo Competición Semana Santa los dias 25, 26 y 27 de Marzo en Club Rio Grande.',
    imagenUrl: '/Events/Intensivo Competición Semana Santa.jpg',


    ruta: '/eventos/intensivosemanasanta', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 

   
  },


  {
    id: 6,
    nombre: 'Torneo de Pádel en Palomares Pádel Club y en Bellavista Huelva Club',
    fecha: '08/06/2024', 
    descripcion: 'Torneo de Pádel en Palomares Pádel Club y en Bellavista Huelva Club, ida 27/04/2024 vuelta 08/06/2024',
    imagenUrl: '/Events/Torneo de Pádel en Palomares Pádel Club y en Bellavista Huelva Club.jpg',


    ruta: '/eventos/torneopadelpalomaresybellavista', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 
  },



  {
    id: 7,
    nombre: 'Campus Semana Santa en G.E Airbus',
    fecha: '25/03/2024 - 27/03/2024', 
    descripcion: 'Campus Semana Santa los dias 25, 26, y 27 de Marzo en G.E Airbus.',
    imagenUrl: '/Events/campus semana santa AIRBUS.jpg',


    ruta: '/eventos/torneopadelpalomaresybellavista', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 
  },





  {
    id: 8,
    nombre: 'Torneo de padel Mucopolisacaridosis',
    fecha: '04/05/2024', 
    descripcion: 'Torneo de padel solidario por la Mucopolisacaridosis.',
    imagenUrl: '/Events/mucopolisacaridosis.jpg',


    ruta: '/eventos/mucopolisacaridosis', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 
  },




  {
    id: 9,
    nombre: 'III Torneo NSTA Kids',
    fecha: '28/04/2024', 
    descripcion: 'Este evento de carácter recreativo, esta dedicado a las edades comprendidas entre los 4 y los 12 años. El objetivo es adentrar a los más pequeños en la competición, que den sus primeros pasos en la misma, que aprendan las reglas de juego básicas, hagan nuevas amistades y fundamentalmente que se enamoren del tenis',
    imagenUrl: '/Events/3torneonstakids.jpg',


    ruta: '/eventos/3torneonstakids', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 
  },



  {
    id: 10,
    nombre: 'Circuito TWE en Río Grande',
    fecha: '26/04/2024 - 28/04/2024', 
    descripcion: 'Continuamos con el 2º Circuito Tennis Warehouse Europe con la celebración del 3er torneo en las magníficas instalaciones de la Asociación Recreativa Río Grande.',
    imagenUrl: '/Events/CircuitoTW.jpg',


    ruta: '/eventos/circuitotw', // VIEW EVENTO

    urlInscripcion: 'https://docs.google.com/forms/d/e/1FAIpQLSd1YV3W3lkakI1Wnmf7swVSEF5kThUity6q-FpxtjHXVP9mAg/viewform?vc=0&c=0&w=1&flr=0', // FORMULARIO 
  },




  {
    id: 11,
    nombre: 'Torneo Dobles NSTA',
    fecha: '1/05/2024', 
    descripcion: 'Torneo Dobles NSTA en Club Rio Grande.',
    imagenUrl: '/Events/TORNEO-DOBLES-NSTA.jpg',


    ruta: '/eventos/torneodobles', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 
  },



  {
    id: 12,
    nombre: 'Torneo Nocturno',
    fecha: '28/06/2024', 
    descripcion: 'Torneo Nocturno Viernes 28 de Junio.',
    imagenUrl: '/Events/Torneo Nocturno.jpeg',


    ruta: '/eventos/torneonocturno28junio', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 
  },




  {
    id: 13,
    nombre: 'Torneo Benéfico para la investigación de la Ataxia Telangiectasia',
    fecha: '29/06/2024 - 30/06/2024', 
    descripcion: 'Torneo Benéfico para la investigación de la Ataxia Telangiectasia',
    imagenUrl: '/Events/Torneo Benéfico para la investigación de la Ataxia Telangiectasia.jpeg',


    ruta: '/eventos/torneotelangiectasia', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 
  },


  {
    id: 14,
    nombre: 'Torneo Federado Bellavista Huelva Club',
    fecha: '05/07/2024 - 07/07/2024',  
    descripcion: ' Torneo Federado en Bellavista Huelva Club del 5 de Julio al 7 de Julio 2024.',
    imagenUrl: '/Events/Torneo Federado Bellavista Huelva Club.jpg',


    ruta: '/eventos/TorneoFederadoBellavista', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 
  },


  {
    id: 15,
    nombre: 'Torneo de Verano Bellavista Huelva Club',
    fecha: '12/07/2024 - 14/07/2024',  
    descripcion: 'Torneo de verano los días 12, 13 y 14 de Julio en Bellavista Huelva Club.',
    imagenUrl: '/Events/Torneo de verano en Bellavista Huelva Club.jpg',


    ruta: '/eventos/torneoveranobhc', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 
  },


  {
    id: 16,
    nombre: 'Spartan Tenis Tour 2024',
    fecha: '19/08/2024 - 25/08/2024',  
    descripcion: 'Spartan Tenis Tour 2024 del 19 de Agosto al 25 de Agosto.',
    imagenUrl: '/Events/spartan2024.jpg',


    ruta: '/eventos/spartan2024', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 
  },


  {
    id: 17,
    nombre: 'Spartan Adultos Tenis Tour 2024',
    fecha: '19/08/2024 - 25/08/2024',  
    descripcion: 'Spartan Adultos Tenis Tour 2024 del 19 de Agosto al 25 de Agosto. Categorías Absoluto y +35.',
    imagenUrl: '/Events/spartanAbsoluto2024.jpg',


    ruta: '/eventos/spartanabsoluto2024', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 
  },
 


  {
    id: 18,
    nombre: 'Torneo Social Club Rio Grande 2024',
    fecha: '30/09/2024 - 05/10/2024',  
    descripcion: 'Torneo Social Club Rio Grande, Categorías: Absoluto Masculino y Femenino Veterano + 45 Masculino',
    imagenUrl: '/Events/Torneo Social Club Rio Grande.jpg',


  ruta: '/eventos/TorneoSocialRioGrande', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 
  },
 

  {
    id: 19,
    nombre: 'Torneo NSTA Series 1.0',
    fecha: '11/10/2024 - 13/10/2024',  
    descripcion: 'Torneo NSTA Series 1.0 Bellavista Huelva Club!',
    imagenUrl: '/Events/TORNEO NSTA SERIES 1.0.jpg',


  ruta: '/eventos/TorneoNstaSeries1.0', // VIEW EVENTO

    urlInscripcion: '', // FORMULARIO 
  },
 





  

];




// Función para convertir una cadena de fecha en un objeto Date
const obtenerFechaInicio = (fecha) => {
  const fechaInicio = fecha.split(' - ')[0]; // Extrae la fecha de inicio en caso de un rango
  const [dia, mes, año] = fechaInicio.split('/').map(Number);
  return new Date(año, mes - 1, dia); // Los meses en JS van de 0 a 11
};


// Función para convertir una cadena de fecha en un objeto Date
const obtenerFechaFin = (fecha) => {
  const fechas = fecha.split(' - ');
  if (fechas.length === 2) {
    const [dia, mes, año] = fechas[1].split('/').map(Number);
    return new Date(año, mes - 1, dia);
  } else {
    return obtenerFechaInicio(fecha); // Si no hay fecha de fin, usa la fecha de inicio
  }
};

// Divide los eventos en próximos y pasados considerando la fecha de fin para eventos de varios días
const dividirEventos = (eventos) => {
  const hoy = new Date();
  hoy.setHours(0, 0, 0, 0); // Establece la hora al inicio del día para comparar correctamente

  const eventosProximos = eventos.filter(evento => {
    // const fechaInicio = obtenerFechaInicio(evento.fecha);
    const fechaFin = obtenerFechaFin(evento.fecha);
    return fechaFin >= hoy;
  });

  const eventosPasados = eventos.filter(evento => {
    const fechaFin = obtenerFechaFin(evento.fecha);
    return fechaFin < hoy;
  });

  return { eventosProximos, eventosPasados };
};

const { eventosProximos, eventosPasados } = dividirEventos(eventos);




// Ordena los eventos por fecha de inicio antes de renderizarlos
const eventosProximosOrdenados = eventosProximos.sort((a, b) => obtenerFechaInicio(a.fecha) - obtenerFechaInicio(b.fecha));
const eventosPasadosOrdenados = eventosPasados.sort((a, b) => obtenerFechaInicio(b.fecha) - obtenerFechaInicio(a.fecha)); // Orden inverso para los pasados



const EventoFicha = ({ evento }) => {
  const navigate = useNavigate(); 

    // Determina si el evento es futuro
    const esEventoFuturo = obtenerFechaInicio(evento.fecha) >= new Date();
  
  const handleLeerMasClick = () => {
    navigate(evento.ruta); 
  };

  const handleInscribeteClick = () => {
    window.location.href = evento.urlInscripcion; // Navega a la URL del formulario
  };


  return (
    <EventoFichaStyled>
      <ImagenContenedor>
        <ImagenEvento src={evento.imagenUrl} alt="Imagen del evento" />
      </ImagenContenedor>
      <NombreEvento>{evento.nombre}</NombreEvento>
      <FechaEvento>
        <FontAwesomeIcon icon={faCalendarAlt} size="1x" color="#4b4b4b" style={{ marginRight: '10px' }} />
        {evento.fecha}
      </FechaEvento>
      <DescripcionFicha>{evento.descripcion}</DescripcionFicha>
      <BotonesContenedor>
        <BotonLeerMas onClick={handleLeerMasClick}>Leer más</BotonLeerMas>
        {evento.urlInscripcion && esEventoFuturo && (
          <BotonInscribete onClick={handleInscribeteClick}>Inscríbete</BotonInscribete>
        )}
      </BotonesContenedor>
    </EventoFichaStyled>
  );
};

const App = () => (
  <>




    <ContenedorEventos>

      <Titulo>PRÓXIMOS EVENTOS</Titulo>
      {eventosProximosOrdenados.length > 0 ? (
        eventosProximosOrdenados.map(evento => (
          <EventoFicha key={evento.id} evento={evento} />
        ))
      ) : (
        <MensajeNoEventos>Próximamente estarán disponibles los próximos eventos. ¡Mantente al tanto!</MensajeNoEventos>
      )}
    </ContenedorEventos>
    <ContenedorEventos>
      <Titulo>EVENTOS PASADOS</Titulo>
      {eventosPasadosOrdenados.map(evento => (
        <EventoFicha key={evento.id} evento={evento} />
      ))}
    </ContenedorEventos>
  </>
);

export default App;