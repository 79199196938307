import React from 'react';
import styled, { keyframes } from 'styled-components';
import Menu from '../../components/Menu/Navbar';
import Header from '../../components/Header/header';
import Logo from '../../components/Logo/logo';
import Footer from '../../components/Footer/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Datos from '../../components/-FireStoreBackend/firestoredatos2';



const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ContainerGeneral = styled.div`
  animation: ${fadeIn} 2s ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Lumier', sans-serif;
  min-height: 100vh;
  background: linear-gradient(135deg, #ece9e6 0%, #ffffff 100%);
`;

const TituloEvento = styled.h2`
  font-weight: bold;
  color: #4b4b4b;
  margin-bottom: 10px;
  margin-top: 50px;
  text-align: center;
  font-size: 29px;
`;

const FechaEvento = styled.div`
  color: #4b4b4b;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
`;

const IconoFecha = styled(FontAwesomeIcon)`
  margin-right: 8px; 
`;

const ImagenEvento = styled.img`
  width: 80%;
  max-width: 700px;
  border-radius: 10px;
  margin-top: 30px;
  align-self: center;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const blinkAnimation = keyframes`
  0%, 100% {
    background-color: #000000;
    color: #a0f000;
  }
  50% {
    background-color: #a0f000;
    color: #000000;
  }
`;


const Boton = styled.a`
  font-size: 15px;
  width: 100%;
  font-family: 'Kobern'; /* Fuente para el botón */
  display: inline-block;
  padding: 15px 20px;
  background-color: #a0f000; // Color inicial
  color: #000000; // Color del texto inicial
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  animation: ${blinkAnimation} 4s infinite; // Aplicar la animación aquí

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;


const Eventos = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <ContainerGeneral>

        <TituloEvento>Torneo NSTA Series 1.0</TituloEvento>

        <FechaEvento>
          <IconoFecha icon={faCalendarAlt} /> 11/10/2024 - 13/10/2024
        </FechaEvento>

        <ImagenEvento src='/Events/TORNEO NSTA SERIES 1.0.jpg' />


        <ButtonContainer>
          <Boton href="https://docs.google.com/forms/d/e/1FAIpQLSfN8iMUPHe2dTmjR0ZB_EIOwZ97hAL2AN5sHv_vynU2dW4iaw/viewform" target="_blank" rel="noopener noreferrer">
            INSCRIPCIÓN
          </Boton>
        </ButtonContainer>



        <Datos>
        </Datos>

      </ContainerGeneral>
      <Footer />
    </div>
  );
};

export default Eventos;
